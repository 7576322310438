@import 'Styles/includes';

/**
*
* HeroSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.HeroSearch {
    $root: &;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: rgba($gray-10, 0.5);
    position: relative;
    color: white;

    &--Inverted {
        color: $text;
    }

    &--HasImage {
        &:before {
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba($black, 0.45);
        }
    }

    &__Button {
        margin-top: 24px;
    }

    &__Content {
        margin: 0 auto;
        max-width: maxwidth(article);
        position: relative;
        padding: 60px 20px 60px 20px;
        text-align: center;

        @include media(md) {
            padding: 82px 24px 90px 24px;
        }
    }

    &__Title {
        line-height: 1.25;
        font-size: 3.2rem;
        margin-bottom: 8px;
        font-weight: bold;

        @include media(md) {
            font-size: 5.2rem;
            margin-bottom: 16px;
            width: 900px;
            margin: 0 auto 16px auto;
        }
    }

    &__Preamble {
        font-size: 1.8rem;
        line-height: 1.5;
        font-weight: 600;

        @include media(md) {
            font-size: 2rem;
            max-width: 644px;
            font-size: 1.8rem;
            margin: 0 auto;
        }

        p a {
            color: white;
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
            text-decoration: underline;

            #{$root}:not(#{$root}--Inverted) & {
                @include href-external {
                    &::after {
                        background-image: url('#{$publicpath}img/icon-external-link--white.svg');
                    }
                }
            }

            #{$root}--Inverted & {
                color: $text;
            }
        }
    }

    &__Search {
        display: none;

        @include media(md) {
            display: block;
            position: relative;
            margin: 16px auto 0 auto;
            width: 100%;
            margin-top: 16px;
            margin: 36px auto 0 auto;
            width: 50%;
        }
    }

    &__SearchField {
        margin: 0;
        outline: none;
        background: white;
        width: 100%;
        border: 1px solid $gray-40;
        padding: 18px 20px 20px 20px;
        color: #0c0c0c;
        font-size: 16px;
        line-height: 19px;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: right 30px center;
        background-size: 13px;
        appearance:none;

        &::-webkit-search-cancel-button {
            display: none;
        }

        &:focus {
            border: 1px solid $black;
        }

        &--Wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            margin-bottom: 15px;
        }

        @include media(lg) {
            margin-right: 10px;
            max-height: 56px;
        }
    }

    &__Loader {
        position: absolute;
        top: 0;
        right: 70px;
        padding: 0;
        width: 50px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__Submit {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        border: none;
        outline: none;
        padding: 0;
        background-image: url('#{$publicpath}img/icon-search.svg');
        width: 70px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px;
        border-radius: 0px 4px 4px 0px;
        cursor: pointer;
        background-color: transparent;

        &--Active {
            background-image: url('#{$publicpath}img/icon-search-white.svg');
            background-color: $button-bg;

            &:hover {
                background-color: $button-bg-hover;

                @media (hover: none) {
                    background-color: $button-bg;
                }
            }

            &:active {
                background-color: $button-bg-active;
            }
        }
        
        &--Alt {
            &#{$root}__Submit--Active {
                background-color: $button-bg-hover;
            }
        }
    }

    &__SearchFieldWrapper {
        position: relative;
    }
}
